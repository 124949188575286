<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <van-nav-bar
      :title="recordDetailTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="cont1">
      <div class="item">
        <p class="code">
          <span class="number">{{ obj.codeNo }}</span>
          <span class="icon">{{ obj.codeStatus }}</span>
        </p>
        <p>{{ obj.skuName }}</p>
        <p>
          <span>{{ $t("record.verifyOrActivateTime") }}</span
          >{{ obj.verifyOrActivateTime }}                                                         
        </p>
      </div>
    </div>
    <div class="cont2">
      <p>
        <span> {{ $t("record.verifyStatus") }}</span>
        <span> {{ obj.verifyStatusStr }}</span>
      </p>
      <p>
        <span> {{ $t("record.createTime") }}</span>
        <span> {{ obj.createTime }}</span>
      </p>
      <p>
        <span> {{ $t("record.storeCode") }}</span>
        <span> {{ obj.storeCode }}</span>
      </p>
      <p>
        <span> {{ $t("record.storeName") }}</span>
        <span> {{ obj.storeName }}</span>
      </p>
      <p>
        <span> {{ $t("record.points") }} </span>
        <span class="money"> {{ obj.points }} <span v-if="obj.points"> {{ $t("record.monetaryUnit") }} </span>  </span>
      </p>
      <p>
        <span> {{ $t("record.withdrawStatus") }}</span>
        <span> {{ obj.withdrawStatusStr }}</span>
      </p>
      <p>
        <span> {{ $t("record.remark") }}</span> <span> {{ obj.remark }}</span>
      </p>
      <p>
        <span> {{ $t("record.salesVoucher") }}</span>
          <span v-if="obj.salesDocumentPath">
            <van-image
            width="96px"
            height="96px"
            class="van_img"
            fit="cotain"
            :lazy-load="lazyLoad"
            @click="showPreview(imageData)"
            :src="imageData"
          >
          <!-- <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template> -->
          <div slot='loading'>
                 <van-loading type="spinner" size="20" />
            </div>
          <div slot='error' class="error">
                {{ $t("stringCode.errorText") }}
            </div>
          </van-image>
          <van-image-preview v-model="showImg" :images="imgList"></van-image-preview>
          </span>
      </p>
    </div>
    <button
      class="btn"
      @click="sureWithdraw "
      v-if="obj.withdrawStatus == 'W'&& !isfromHome"
      :disabled="loading"
    >
      {{ $t("record.Withdraw") }}
    </button>
  </div>
</template>
<script>
import { verifyDetail, apply,downloadSalesDocument } from "@network/api";

export default {
  name: "",
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      lazyLoad:true,
      showImg:false,
      imgList:[],
      loading: false,
      recordDetailTitle: "",
      status: "s",
      // s 成功 f 失败 d 处理中   W 待提现
      createTime: "2020-12-26 12:23:56",
      successTime: "2020-12-26 12:23:56",
      cashoutFailBoxshow: false,
      mengcengshow: false,
      recordNo: "",
      isfromHome:false,
      obj: {
        codeNo: "8003295657346",
        verifyOrActivateTime: "2020-12-26 12:23:56",
        createTime: "2020-12-26 12:23:56",
        storeName: "SE首销季以旧换新挑战赛激励",
        skuName: "Reno5 Pro+ 5G PDRM00（256GB 12GB）全网通星河入梦",
        verifyStatus: "",
        withdrawStatus: "",
        points: 30,
        storeName: "XXXX银行(2980)",
        storeCode: "0391000149",
        codeStatus: "Activated",
      },
      imageData:'',//销售凭证
    };
  },
  mounted() {
    this.recordNo = this.$route.params.recordNo? this.$route.params.recordNo : localStorage.getItem('recordNo');
    this.isfromHome = this.$route.params.from =='home'? true:false;
    this.getPath();
  },
  methods: {
    showPreview(url){
      this.imgList.push(url);
      this.showImg=true;
    },
    getViewImg(val){
      const salesDocumentPath = {
        filePath: val
      }
      downloadSalesDocument(salesDocumentPath)
        .then((res) => {
          console.log(res, 'pppp')
          if (res.code == '000000') {
            const url = 'data:image/png;base64,' + res.data.img
			      this.imageData = url
            this.lazyLoad=false
            this.loading=false
          } else {
            // this.$toast(res.message);
            this.lazyLoad=false
          }
        })
        .catch((err) => {
          console.log(err.message)
          this.loading=false
        })
    },
    getPath() {
      if (this.$route.path == "/recordDetail") {
        this.recordDetailTitle = this.$t("withdrawResult.title");
        this.recordNo = this.$route.params.recordNo? this.$route.params.recordNo : localStorage.getItem('recordNo');
        this.loading = true;
        verifyDetail({ recordNo: this.recordNo })
          .then((res) => {
            this.loading = false;
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
              this.obj = res.data;
              if(res.data.salesDocumentPath){
                this.getViewImg(res.data.salesDocumentPath)
              }else{
                this.lazyLoad=false
              }
              
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });
      }
    },
    back() {
      this.$router.back(-1);
    },
    
    sureWithdraw() {
      this.$router.push({
                name: "withdraw",
                params: {
                  recordNo: this.recordNo,
                  points: this.obj.points,
                  skuName:  this.obj.skuName ,
                  activityName:  this.obj.activityName,
                },
              });
    },
    closecashoutFailBox() {
      this.cashoutFailBoxshow = false;
      this.mengcengshow = false;
    },
  },
};
</script>
<style scoped>
.cont1 {
  margin-top: 46px;
  padding: 8px 24px;
}
.item {
  border-bottom: 1px solid #eff0f2;
  position: relative;
}
.code {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #333333;
}

.code .number {
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: bold;
}

.item p {
  color: #999;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin: 9px 0;
}
.icon {
  text-align: center;
  height: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  background: #2dd070;
  border-radius: 5px;
  display: inline-block;
  padding: 0 3px;
  margin-left: 8px;
}
.cont2 {
  padding: 0px 24px;
}
.cont2 p {
  font-size: 14px;
  line-height: 25px;
  min-height: 25px;
  display: flex;
}
.cont2 p > span:nth-child(1) {
  color: #999;
  display: inline-block;
  width: 128px;
  display: flex;
}
.cont2 p > span:nth-child(2) {
  font-size: 14px;
  color: #333333;
  flex: 1;
}
.money {
  color: #2dd070 !important;
  font-weight: bold;
}
.btn {
  line-height: 44px;
  color: #fff;
  text-align: center;
  width: 86%;
  height: 44px;
  margin-left: 7%;
  margin-top: 50px;
  font-size: 14px;
  background: #2dd070;
  border-radius: 12px;
}
.btn:disabled {
  opacity: 0.35;
}
.van_img {
  width: 96px;
  height: 96px;
}
</style>
